// import HeroM from "../components/About/HeroM";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import Contact1 from '../components/Uconstruction/Contact1';
// import AboutImg from "../assets/2.jpg";
// import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <>
       <Navbar />
       <Contact1/>
       <Footer/>
      
    </>
  );
}

export default Contact;
